import React from 'react'
import axios from "axios";

import Layout from "../layouts/layout"
import SEO from "../components/seo"

import contactStyles from "./contact.module.css"

const ContactPage = () => {
    
    const [serverState, setServerState] = React.useState({
        submitting: false,
        status: null
    });
    
    const handleServerResponse = (ok, msg, form) => {
        setServerState({
            submitting: false,
            status: { ok, msg }
        });
        if (ok) {
            form.reset();
        }
    };
    
    const handleOnSubmit = e => {
        e.preventDefault();
        const form = e.target;
        setServerState({ submitting: true });
        axios({
            method: "post",
            url: "https://getform.io/f/42fa5359-484f-434f-b189-e2a557a61fa1",
            data: new FormData(form)
        })
        .then(r => {
            handleServerResponse(true, "Thanks!", form);
        })
        .catch(r => {
            handleServerResponse(false, r.response.data.error, form);
        });
      };

      return (
        <Layout>
		    <SEO title="Contact" />
            <h2>En quoi puis-je vous aider ?</h2>
            <form onSubmit={handleOnSubmit}>
                {serverState.status && (
                    <p className={serverState.status.ok ? contactStyles.successMessage : contactStyles.errorMessage}>
                        {serverState.status.ok 
                            ? 'Merci ! Je vous recontacte rapidement.'
                            : 'Une erreur est survenue.'}
                    </p>
                )}
                <div className={contactStyles.inputControl}>
                    <label htmlFor="name">Nom</label>
                    <input type="text" name="name" placeholder="Nom" aria-labelledby="name" />
                </div>
                <div className={contactStyles.inputControl}>
                    <label htmlFor="company">Entreprise / Société</label>
                    <input type="text" name="company" placeholder="Entreprise / Société" aria-labelledby="company" />
                </div>
                <div className={contactStyles.inputControl}>
                    <label htmlFor="email">Adresse e-mail</label>
                    <input type="email" name="email" placeholder="E-mail"aria-labelledby="email" />
                </div>
                <div className={contactStyles.inputControl}>
                    <label htmlFor="message">Message</label>
                    <textarea type="text" name="message" placeholder="Message" aria-labelledby="message"></textarea>
                </div>
                <button 
                    type="submit" 
                    className={`${contactStyles.sendButton} contact-button`}
                    disabled={serverState.submitting}
                    >
                    Envoyer
                </button>
            </form>
        </Layout>
      );
};

export default ContactPage;